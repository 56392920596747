let Common = {

    init : () => {

        Common.Search();
        Common.ImageCheck();
        Common.FixBtn();
        Common.FlashNotice();
        Common.ModalMessenger();
    },

    FlashNotice: () => {

        $d.off('click', '.flash-notice-close-btn').on('click', '.flash-notice-close-btn', function(){
            $(this).parent('.flash-notice-close').parent('.flash-notice').removeClass('flash-notice-visible');
        });
    },

    ModalMessengerClear : () => {
        Common.ModalMessengerFileClear();
        $('#messenger_message_from').val('');
        $('#messenger_message_to').val('');
        $('#messenger_message_category').val('');
        $('#messenger_message_content').val('');
        $('#modal_messages_list_loader').removeClass('modal-messages-list-loader-hidden');
        $('#dynamic_text').text('');
        $('#modal_messages_list_empty').hide();
        $('#list_messages').html('');
        if ($('#list_subcontractors').length) $('#list_subcontractors').hide();

        //$('#list_messages').removeClass('modal-width-subcontractors');
        $('#modal_messages_list').removeClass('modal-width-subcontractors');
        $('#modal_messages_list_loader').removeClass('modal-width-subcontractors');
        $('#modal_messages_list_empty').removeClass('modal-width-subcontractors');
        $('#messenger_search_subcontractors').val('');
    },

    ModalMessengerFileClear : () => {
        $('#messenger_message_file_trash').remove();
        $('#messenger_message_file_label').removeClass('messenger-message-as-file');
        $('#messenger_message_file_icon').html('<i class="fas fa-paperclip"></i>');
        $('#messenger_message_file_type').text('');
        $('#messenger_message_file_size').text('');
        $('#messenger_message_file').val('');
    },

    LoadMessengerSubcontractors : (data, callback) => {

        $('#modal_messages_list_loader').removeClass('modal-messages-list-loader-hidden');

        let Ajax = $.ajax({
            url : '/messenger/subcontractors/load',
            method: 'POST',
            data: data
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            console.log(error);
        });

    },

    ModalMessenger : () => {

        $d.off('click', '.contact-messenger').on('click', '.contact-messenger', function(){

            let btnText     = $(this).text(), 
                from        = $(this).attr('from'), 
                to          = $(this).attr('to'), 
                category    = $(this).attr('category')
                objectId    = $(this).attr('object-id')
            ;

            Common.ModalMessengerClear();

            $('#modal_message').show();
            $('#dynamic_text').text(' - '+btnText);

            $('#messenger_message_from').val(from);
            $('#messenger_message_to').val(to);
            $('#messenger_message_category').val(category);
            $('#messenger_message_object_id').val(objectId);

            Common.ModalMessengerLoadMessage({
                body_id : $('body').attr('id'),
                from : from,
                to : to,
                category : category,
                object_id : objectId
            });
        });

        //subcontractor-item

        $d.off('click', '.contact-messenger-stt').on('click', '.contact-messenger-stt', function(){

            let btnText     = $(this).text(), 
                from        = $(this).attr('from'), 
                to          = $(this).attr('to'), 
                category    = $(this).attr('category')
                objectId    = $(this).attr('object-id')
            ;
            

            Common.ModalMessengerClear();
            if ($('#list_subcontractors').length) {
                $('#list_subcontractors').show();
                //$('#list_messages').removeClass('modal-width-subcontractors').addClass('modal-width-subcontractors');
                $('#modal_messages_list').removeClass('modal-width-subcontractors').addClass('modal-width-subcontractors');
                $('#modal_messages_list_loader').removeClass('modal-width-subcontractors').addClass('modal-width-subcontractors');
                $('#modal_messages_list_empty').removeClass('modal-width-subcontractors').addClass('modal-width-subcontractors');
            }
            $('#modal_message').show();
            $('#dynamic_text').text(' - '+btnText);

            Common.LoadMessengerSubcontractors({ 'type' : category, 'object_id' : objectId}, function(result){

                $('#modal_messages_list_loader').addClass('modal-messages-list-loader-hidden');
                $('#list_subcontractors_items').html('');

                if (result && result.response === 'success' && result.datas.length) {

                    $.each(result.datas, function(index, data) {

                        let unread = data.unread_message > 0 ? '<div class="item-unread"><span>'+data.unread_message+'</span></div>' : '';

                        $('#list_subcontractors_items').append(`
                            <div class="subcontractor-item" object-id="${objectId}" category="consultation_marches_public" from="${from}" to="${data.id}">
                                <i class="far fa-address-card"></i>
                                <div class="item-society-name">${data.societe}</div>
                                ${unread}
                            </div>
                        `);
                    });

                }
            });
        });

        $d.off('click', '.subcontractor-item').on('click', '.subcontractor-item', function(){

            $('.subcontractor-item').removeClass('subcontractor-item-active');
            $(this).addClass('subcontractor-item-active');

            let from        = $(this).attr('from'), 
                to          = $(this).attr('to'), 
                category    = $(this).attr('category')
                objectId    = $(this).attr('object-id')
            ;

            $('#list_messages').html('');

            $('#messenger_message_from').val(from);
            $('#messenger_message_to').val(to);
            $('#messenger_message_category').val(category);
            $('#messenger_message_object_id').val(objectId);

            Common.ModalMessengerLoadMessage({
                body_id : $('body').attr('id'),
                from : from,
                to : to,
                category : category,
                object_id : objectId
            });

        });

        $d.off('click', '.modal-message-close').on('click', '.modal-message-close', function(){
            $('#modal_message').hide();
            Common.ModalMessengerClear();
        });

        $d.off('submit', '#messenger_message_form').on('submit', '#messenger_message_form', function(e){
            e.preventDefault();

            let formData = new FormData();
            let files   = $('#messenger_message_file')[0].files;

            if(files.length > 0 ){
                formData.append('messenger_message_file',files[0]);
            }

            let message = $('#messenger_message_content').val();

            formData.append('body_id', $('body').attr('id'));
            formData.append('from', $('#messenger_message_from').val());
            formData.append('to', $('#messenger_message_to').val());
            formData.append('message', message);
            formData.append('category', $('#messenger_message_category').val());
            formData.append('object_id', $('#messenger_message_object_id').val());

            Common.ModalMessengerSendMessage(formData);
        });

        $d.off('change', '#messenger_message_file').on('change', '#messenger_message_file', function(e){

            $('#messenger_message_file_label').addClass('messenger-message-as-file');
            $('#messenger_message_file_icon').html('<i class="far fa-file-alt"></i>');
            $('#messenger_message_file_type').text(e.target.files[0].type);
            $('#messenger_message_file_size').text(Common.FileConvertSize(e.target.files[0].size));
            $('#messenger_message_file_label').append('<span id="messenger_message_file_trash" class="messenger-message-file-trash"><i class="fas fa-trash-alt"></i></span>');
        });

        $d.off('click', '#messenger_message_file_trash').on('click', '#messenger_message_file_trash', function(e){

            e.preventDefault()
            e.stopPropagation();
            Common.ModalMessengerFileClear();
        });

        $d.off('keyup keydown', '#messenger_search_subcontractors').on('keyup keydown', '#messenger_search_subcontractors', function(e){
            let value = $(this).val();
            if (value.length > 1) {
                $('.subcontractor-item').hide();
                $('.subcontractor-item').each(function(index, item){
                    let itemText = $(item).text().trim();
                    if ((itemText.toLowerCase()).indexOf((value.toLocaleLowerCase())) !== -1) {
                        $(item).show();
                    }
                });
            } else {
                $('.subcontractor-item').show();
            }
        });

        $d.off('mouseenter', '.message-item-container').on('mouseenter', '.message-item-container', function(){

            let id      = $(this).attr('id');
            let $that   = $(this);
            let isCurrenUser = $that.children('.message-item').hasClass('is-current-user') ? '1' : '0';
            let isRead = $that.children('.message-item').children('.message-item-date').children('.item-read').hasClass('is-read');

            if (!isRead) {
    
                Common.ModalMessageIsRead({message_id : id, is_current_user : isCurrenUser}, function(result){
                    if (result && result.response === 'success' && result.datas.length) {
                        $that.children('.message-item').find('.message-item-date').children('.item-read').removeClass('is-not-read').addClass('is-read');
                    }
                });
            }
        });
    },

    ModalMessageIsRead : (data, callback) => {

        let Ajax = $.ajax({
            url : '/messenger/message/isread',
            method: 'POST',
            data: data
        });

        Ajax.done(function(result){
            return callback(result);
        });

        Ajax.fail(function(error){
            console.log(error);
            return callback(error);
        });

    },

    ModalMessengerLoadMessage : (data) => {

        $('#modal_messages_list_loader').removeClass('modal-messages-list-loader-hidden');
        $('#modal_messages_list_empty').hide();

        //modal_messages_list_empty

        let Ajax = $.ajax({
            url : '/messenger/message/load',
            method: 'POST',
            data: data
        });

        Ajax.done(function(result){
            Common.LoadResultMessage(result);
        });

        Ajax.fail(function(error){
            console.log(error);
        });
    },

    LoadResultMessage : (result) => {

        $('#modal_messages_list_loader').addClass('modal-messages-list-loader-hidden');

        if (result && result.response) {

            $('#modal_messages_list_empty').hide();

            if (result.response === 'success' && result.datas.length) {

                $('#list_messages').html('');

                let itemLength = result.datas.length, count = 0;

                $.each(result.datas, function(index, data) {

                    let fichier_url = data.fichier;
                    if (fichier_url && fichier_url.startsWith('__CLEMENTINE_CONTENUS_WWW_ROOT__')) {
                        fichier_url = v3_transporturl + "/messagerie/read?andco_messagerie-id=" + data.id +"&file=andco_messagerie.fichier";
                    }
                    let fichier         = fichier_url ? '<a target="_blank" href="'+fichier_url+'"><i class="fas fa-paperclip"></i> '+data.fichier.split('/').pop()+'</a>' : '';
                    let classMessage    = data.is_current_user === '1' ? 'message-item-1 is-current-user' : 'message-item-2';
                    let classRead       = data.date_lecture ? 'is-read' : 'is-not-read';

                    $('#list_messages').prepend(
                        `<div id="${data.id}" class="message-item-container">
                            <div class="message-item ${classMessage}">
                                <div class="message-item-society"><i class="far fa-address-card"></i> <span>${data.sender_society}</span> <small>${(data.type).split('_').join(' ')}</small></div>
                                <div class="message-item-text">${data.message}</div>
                                <div class="message-item-file">${fichier}</div>
                                <div class="message-item-date">
                                    <div class="item-read ${classRead}"><i class="fas fa-check"></i></div>
                                    <div class="item-date">${data.date_envoi}</div>
                                </div>
                            </div>
                        </div>`
                    );

                    count++;

                    if (count === itemLength) {

                        $('#list_messages').prepend('<div class="message-load-more"><i class="fas fa-ellipsis-h js-message-load-more"></i></div>');
                        $('#modal_messages_list_loader').addClass('modal-messages-list-loader-hidden');
                        $("#modal_messages_list").scrollTop($("#modal_messages_list")[0].scrollHeight);
                    }
                });
            } else {
                //$('#modal_messages_list_loader').addClass('modal-messages-list-loader-hidden');
                $('#modal_messages_list_empty').show();
            }
        }
    },

    ModalMessengerSendMessage : (data) => {

        //$('#modal_messages_list_loader').removeClass('modal-messages-list-loader-hidden');
        $('#messenger_message_content').val('');
        $('#messenger_message_file_trash').trigger('click');

        let Ajax = $.ajax({
            url : '/messenger/message/add',
            method: 'POST',
            data: data,
            processData: false,
            contentType: false
        });

        Ajax.done(function(result){
            Common.LoadResultMessage(result);
        });

        Ajax.fail(function(error){
            console.log(error);
        });
    },

    FixBtn : () => {

        if ($('.btn-fixed').length) {
            $('.btn-fixed').addClass('btn-fixed-active');
        }
    },

    FileConvertSize : (aSize) => {
        aSize = Math.abs(parseInt(aSize, 10));
        var def = [[1, 'octets'], [1024, 'ko'], [1024*1024, 'Mo'], [1024*1024*1024, 'Go'], [1024*1024*1024*1024, 'To']];
        for(var i=0; i<def.length; i++){
            if(aSize<def[i][0]) return (aSize/def[i-1][0]).toFixed(2)+' '+def[i-1][1];
        }
    },

    Search : () => {

        if ($('#search_page_form').length > 0) {

            $d.off('click', '.search-page-close').on('click', '.search-page-close', function(){
                $('.search-page-container').remove();
                return;
            })

            $d.off('submit', '#search_page_form').on('submit', '#search_page_form', function(e){
                e.preventDefault();

                $('.search-page-container').remove();

                $('body').append('<div class="search-page-container"><div class="search-page-close"><i class="fas fa-times"></i></div><div class="search-page-items"></div></div>');

                let value = $(this).children('input[name="search_page"]').val();

                $('#sidebar').find('a').each(function(index, item){

                    let text = $(item).text(), href = $(item).attr('href');

                    if (text.includes(value) || href.includes(value)) {

                        $('.search-page-container').find('.search-page-items').append($(item).clone());
                    }
                });
                $(this).children('input[name="search_page"]').val('');
            });
        }
    },

    ImageCheck : () => {
        if ($('img').length) {
            let $img = $('img');
            $img.each(function(index, img) {
                let src = $(img).attr('src');
                if (src.indexOf('andcotransport.com') === -1 && src.indexOf('andcotransportv3.dev.docker') === -1) {
                    Common.ImageExist((window.location.origin+src), function(exist){
                        if (!exist) {
                            $(img).attr('src', '/img/not-available.jpg');
                        }
                    });
                }
            });
        }
    },

    ImageExist : (url, callback) => {

        const img = new Image();
        img.src = url;
        
        if (img.complete) {
            callback(true);
        } else {
            img.onload = () => {
            callback(true);
            };
            
            img.onerror = () => {
            callback(false);
            };
        }

    },

    numberFormat : (data, decimal=2) => {

        if (typeof data === 'string') {

            data = data.split(' ').join('');

            if (data && data != 0 && data.indexOf(',')) {
                data = data.replace(',', '.');
                
            }
        }
        if(isNaN(data)){
            return 0;
        } else {

            data += '';
            x = data.split('.');
            x1 = x[0];
            if (decimal > 0) {
                x2 = x.length > 1 ? ',' + x[1].substring(0, decimal) : '';
            } else {
                x2 = '';
            }
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        }
    },
}
module.exports = Common;
