let Offers = {

    init : () => {
        Offers.Actions();
    },

    Actions : () => {

        $d.off('click', '.mac-point-ball').on('click', '.mac-point-ball', function(e){

            if ($(this).parent().children('.mac-point-content').is(':visible')) {
                $(this).parent().children('.mac-point-content').fadeOut(300);
                return;
            }

            $('.mac-point-content').hide();
            $(this).parent().children('.mac-point-content').fadeIn(300);
            return;
        });
    }
}
module.exports = Offers;