let OfferMask = {
    init: () => {

        console.log('*** Offer Mask ***')

        //require('./Leaflet').init('map', true);

        let selectId = 'select#offer_offerMask';
        document.querySelector(selectId).addEventListener('change', OfferMask.loadMask);
        OfferMask.applyMask(document.querySelector(selectId + ' option:checked').value);
    },
    loadMask: (event) => {
        let optionValue = event.target.value;
        OfferMask.applyMask(optionValue);
    },
    applyMask: (optionValue) => {
        let shownFields = OfferMask.getFields(optionValue, 'is_shown');
        let requiredFields = OfferMask.getFields(optionValue, 'is_required');
        OfferMask.displayFields(shownFields, requiredFields);
    },
    getFields: (optionValue, type) => {
        let inputName = 'input[name="offer_masks[' + optionValue + '][' + type + ']"]';
        let fields = document.querySelector(inputName).value;
        fields = JSON.parse(fields);

        return fields;
    },
    displayFields: (shownFields, requiredFields) => {
        let elements = document.getElementsByClassName('form-group');
        elements.forEach(function(element) {
            element.classList.remove('d-none');
            let elementId = element.id;
            if (undefined != elementId && '' != elementId) {
                let split = elementId.split('-');
                let fieldname = split[0].replace('offer_', '');
                if (true == shownFields[fieldname]) {
                    if (true == requiredFields[fieldname]) {
                        element.children.forEach(function(children) {
                            if ('LABEL' == children.tagName) {
                                children.classList.add('required');
                            } else if ('INPUT' == children.tagName) {
                                children.setAttribute('required', true);
                            }
                        });
                    }
                } else {
                    element.classList.add('d-none');
                }
            }
        });
    },
}
module.exports = OfferMask;
