const { stat } = require('fs');

let PublicMarket = {
    init: () => {
        PublicMarket.Actions();
        PublicMarket.DatePicker();
        PublicMarket.OnLoad();
        PublicMarket.InputChangeLot();
        PublicMarket.InputChangeSubcontractor();
        PublicMarket.SubcontractorListChoice();
        PublicMarket.LotsNav();
        PublicMarket.Departments();
        PublicMarket.StatusField();
        //PublicMarket.EmailField();
        PublicMarket.RedirectToV3();
        PublicMarket.ChangeKilometersOnUpload();
    },

    CheckRequiredFile : (callback) => {

        let synthesisFile = false, ruleFile = false, contractFile = false, lotFile = true;
        // Synthesis
        if ($('.ajax_public_market_synthesis').length) synthesisFile = true;
        if ($('input[name="publicmarket_synthesis"]').length) synthesisFile = true;
        // Rule
        if ($('.ajax_public_market_rule').length) ruleFile = true;
        if ($('input[name="public_market_rule"]').length) ruleFile = true;
        // Contract
        if ($('.ajax_public_market_contract').length) contractFile = true;
        if ($('input[name="public_market_contract"]').length) contractFile = true;

        if ($('.public_market_lot-input-file').length) {

            let itemLenght = $('.public_market_lot-input-file').length, count = 0;

            $('.public_market_lot-input-file').each( function(index, item){
                let objectName = $(item).attr('object-name') ? $(item).attr('object-name') : null;
                if (objectName) {
                    if (!$('.ajax_'+objectName).length) {
                        lotFile = false;
                    }
                }
                count++;
                if (count === itemLenght) {
                    if (synthesisFile && ruleFile && contractFile && lotFile) {
                        return callback('1');
                    } else {
                        return callback(false);
                    }
                }
            });
        } else {
            if (synthesisFile && ruleFile && contractFile && lotFile) {
                return callback('1');
            } else {
                return callback(false);
            }
        }
    },
    
    Actions : () => {

        let Common = require('./Common');

        let formSubmitText = $('#form_submit').html();

        $d.off('pointerenter', '#form_submit').on('pointerenter', '#form_submit', function(){

            $('#form_submit').prop('disabled', true).html('<i class="fas fa-ban"></i>').addClass('btn-fixed-red');

            PublicMarket.CheckRequiredFile(function(result){
                
                if (result && result === '1') {
                    $('#form_submit').prop('disabled', false).html(formSubmitText).removeClass('btn-fixed-red');
                    return;
                } else {
                    $('#form_submit').prop('disabled', true).html('<i class="fas fa-ban"></i>').addClass('btn-fixed-red');
 
                    $('#public_market_notice').show();
                    $('.notice-content-info-required-fields').show();
                    return;
                }
            });
        });

        $d.off('pointerleave', '#form_submit').on('pointerleave', '#form_submit', function(){

            $('#form_submit').prop('disabled', false).html(formSubmitText).removeClass('btn-fixed-red');

            $('.notice-content-info').hide();
            $('#public_market_notice').hide();
            return;
        });

        $d.on('keypress', function(event){
            if (event.keyCode == '13') event.preventDefault();// On bloque la touche ENTREE
        });

        $d.off('change', '.public-market-lot-kilometers').on('change', '.public-market-lot-kilometers', function(){
            let value = $(this).val();
            value = Common.numberFormat(value, 2);
            $(this).val( value ).attr('value', value);
        });

        $d.off('blur', '#public_market_price').on('blur', '#public_market_price', function(){
            let value = $(this).val();
            value = Common.numberFormat(value, 0);
            $(this).val( value ).attr('value', value);
        });

        $d.off('keypress', '#public_market_price').on('keypress', '#public_market_price', function(){
            const regex = new RegExp("^[0-9]+$");
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });

        if ($('.public-market-lot-kilometers').length) {

            $('.public-market-lot-kilometers').each(function(index, item) {
                let value = $(item).val();
                value = Common.numberFormat(value, 2);
                $(item).val( value ).attr('value', value);
            });
        }

        $d.off('click', '#public_market_alert_licenses_modal_close').on('click', '#public_market_alert_licenses_modal_close', function(){
            $('#public_market_alert_licenses_modal').remove();
        });
    },

    RedirectToV3 : () => {

        // >> -- Redirige vers la fiche utilisateur de V3 si confirmation --
        $d.off('click', '.response-item-redirect-v3-user').on('click', '.response-item-redirect-v3-user', function(e){

            e.preventDefault();
            let href = $(this).attr('href');
            $('#confirmation_redirection_to_user_modal').show();

            $d.off('click', '#redirection_to_user_confirm').on('click', '#redirection_to_user_confirm', function(e){
                window.location.href = href;
            });
        });
        // <<


        // >> -- Annule la redirection vers la fiche utilisateur V3 --
        $d.off('click', '#redirection_to_user_cancel').on('click', '#redirection_to_user_cancel', function(e){
            $('#confirmation_redirection_to_user_modal').hide();
        });
        // <<

    },

    OnLoad : () => {

        let Common = require('./Common');

        //let publicMarketPrice = $('#public_market_price').val();
        //$('#public_market_price').val(Common.numberFormat(publicMarketPrice, 0));

        PublicMarket.CheckResponses();

        let year = new Date().getFullYear();

        $('#global_loader').show();

        if ($('#public_market_stopAt_time').val() === '') {

            $('#public_market_stopAt_time').val('16:00');

            // >> -- on modifie l'année des indices --
            $('#public_market_dieselIndexAt_year').val(year);
            $('#public_market_dieselIndexProAt_year').val(year);
            $('#public_market_gasIndexAt_year').val(year);
            $('#public_market_engieIndexAt_year').val(year);
            $('#public_market_electricityIndexAt_year').val(year);
            $('#public_market_hydrogenIndexAt_year').val(year);
            // <<
        }
        $('fieldset > div[disabled] input, fieldset > div[disabled] select, fieldset > div[disabled] button, fieldset > div[disabled] textarea').each(function (index, item) {
            $(this).prop('disabled', true);
        });

        //if (!$('#public_market_engieIndex').val().length) $('#public_market_engieIndex').val('0')
    },

    DatePicker : () => {

        if ($('#public_market_stopAt_date').length) {
            var todayDate = new Date().toISOString().slice(0, 10);
            $('#public_market_stopAt_date').attr('min', todayDate);
        }
    },

    CheckResponses : () => {

        if ($('.response-lot-label-container').length) {
            $('.response-lot-btn').remove();
        }

        if ($('._response-lot-btn').length) {
            $('._response-lot-btn').each(function(index, item) {
                let number = $(item).attr('data-index');
                if ($('.public-market-lot-nav-item[data-number="'+number+'"]').attr('data-user') == 'stt') {
                    $('.public-market-lot-nav-item[data-number="'+number+'"]').addClass('public-market-lot-nav-item-green');
                } else {
                    $('.public-market-lot-nav-item[data-number="'+number+'"]').addClass('public-market-lot-nav-item-purple');
                }
            });
        }

        if ($('.reponse-item').length) {

            $('.public-market-lot-container').each(function(index, item) {

                if ($(item).find('.reponse-item')) {
                    let number = $(item).find('.reponse-item').first().attr('data-index');
                    if ($('.public-market-lot-nav-item[data-number="'+number+'"]').attr('data-user') == 'stt') {
                        $('.public-market-lot-nav-item[data-number="'+number+'"]').addClass('public-market-lot-nav-item-green');
                    } else {
                        $('.public-market-lot-nav-item[data-number="'+number+'"]').addClass('public-market-lot-nav-item-purple');
                    }
                }
            });
        }
    },

    EmailField : () => {

        $d.off('click', '#email_not_selected_btn').on('click', '#email_not_selected_btn', function(){
            $('#error_empty_value').hide();
            if ($('#public_market_email_not_selected').val().length < 1) {
                $('#error_empty_value').show();
                return;
            }
            $('#public_market_modal_email_not_selected').hide();
            return;
        });
    },

    LotsNav : () => {
        let lot_nav_container_position = $('#lot_nav_container').position();
        window.addEventListener("scroll", (event) => {
            if (window.pageYOffset >= (lot_nav_container_position.top - 100)) {
                $('#lot_nav_container').addClass('lot-nav-fixed').css('top', ($('header').outerHeight() + 30)+'px');
            } else {
                $('#lot_nav_container').removeClass('lot-nav-fixed').removeAttr('style');
            }
        });


        $d.off('click', '.public-market-lot-nav-item').on('click', '.public-market-lot-nav-item', function(e) {
            e.preventDefault();

            let number = $(this).attr('data-number');

            $('html, body').animate({
                scrollTop: ($('.public-market-lot-container[data-number="'+number+'"]').offset().top - ($('header').outerHeight() + $('#lot_nav_container').outerHeight() + 40) )
            }, 100);
        })
    },

    Departments : () => {

        let currentDep = $('textarea#public_market_departments').val();

        if (currentDep.length) {

            currentDep = currentDep.split(',');

            if (currentDep.length >= 95) {
                $('#public_market_departments_all').show();
                $('#add-all-departments').prop('checked', true);

                if ($('#public_market_departments_all').is(':visible')) {
                    let text = $('#public_market_departments_all').text();
                    $('#publicmarket_subcontractors_loader').show().find('span').text(text);
                }
            }
        }

        $d.off('change', 'input#add-all-departments').on('change', 'input#add-all-departments', function(){

            let formDep = $(this).attr('data-dep');

            $('textarea#public_market_departments').val('');
            if ($(this).prop('checked')) {
                PublicMarket.addAllDepartments();

                $('#public_market_departments_all').show();
            } else {
                $('#public_market_departments_all').hide();
                $('textarea#public_market_departments').val(formDep);
            }
        });
    },

    StatusField : () => {

        let statusValue = $('#public_market_status').val();

        if (statusValue === 'draft') {
            $('#public_market_status').removeClass('public-market-status-published').addClass('public-market-status-draft')
        }
        if (statusValue === 'published') {
            $('#public_market_status').removeClass('public-market-status-draft').addClass('public-market-status-published')
        }

        // >> -- Au passage de la souris sur le champ de changement de status de l'offre, 
        // >> on controlle les champs obligatoire pour pouvoir publier l'offre --
        $d.off('mouseenter', '#public_market_status').on('mouseenter', '#public_market_status', function(){
            PublicMarket.CheckState();
        });
        // <<

        $d.off('change', '#public_market_status').on('change', '#public_market_status', function(){

            let value = $(this).val();

            if (value === 'draft') {
                $(this).removeClass('public-market-status-published').addClass('public-market-status-draft')
            }
            if (value === 'published') {
                $(this).removeClass('public-market-status-draft').addClass('public-market-status-published')
            }

            if (value != 'published' || $('#public_market_current_turn').val() != '1') return;
            
            //if($('input[name="subcontractor_list_choice[]"]').prop('checked')) return

            $('#public_market_modal_email_not_selected').fadeIn(200);
            
            return false;
        });

        $d.off('click', '#email_not_selected_close_btn').on('click', '#email_not_selected_close_btn', function(){
            $('#public_market_modal_email_not_selected').fadeOut(200);
            return false;
        })
    },

    CheckState : () => {

        let nbItem  = $('.public-market-lot-container:visible').length;
        let $userlist = $('#subcontractor_list');

        // >> -- Si le selecteur de changement de status de l'offre est désactivé, on bloque la publication. --
        if ($('#public_market_status').prop('disabled')) {
            return;
        }
        // <<

        if (nbItem > 0) {

            let value = parseInt($('input#public_market_lotNumber').val()); // >> -- On récupère la valeur du nombre de lot --

            PublicMarket.LotModal(value, function(result){

                if (result) {

                    let isValid = true, count = 0;

                    $('#public_market_status').children('option[value="closed"]').remove();//WIP

                    // >> -- Si aucun sous-traitants dans la liste, il sera impossible de publier l'offre --
                    if ($userlist.length < 1) {
                        isValid = false;
                    }
                    // <<

                    // >> -- Si le tour est supérieur à 1 on ajoute le champ de publication de l'offre --
                    if (parseInt($('#public_market_current_turn').val()) > 1) {
                        isValid  = true;
                    }
                    // <<

                    $('.public-market-lot-container:visible').each(function(index, item) { // >> -- On bloucle sur les lots --

                        let number = $(item).attr('data-number');

                        let fieldNameValue = $('#public_market_public_market_lot_'+number+'_title').val();
                        let fieldKmValue   = parseInt($('#public_market_public_market_lot_'+number+'_kilometers').val());

                        if (fieldNameValue.length < 1) { // >> -- Si les champs title des lots ne sont pas remplis on bloque la publication --
                            isValid  = false;
                        }
                        
                        count++;

                        if (count === nbItem) { // >> -- Si la boucle est terminé, on check.. --

                            if (!isValid) { // >> -- Si non valide, on supprime le champ de publication --
                                $('#public_market_status').children('option[value="published"]').remove();

                            } else {
                                // >> -- Si tout est OK, on ajoute le champ de publication de l'offre --
                                if ($('#public_market_status').children('option[value="published"]').length <= 0) {
                                    $('#public_market_status').append('<option value="published">Publication</option>');
                                }
                                // <<
                            }
                            // >> -- On masque le loader --
                            $('#global_loader').hide();
                            // <<
                        }
                    });
                    PublicMarket.displayLots(value);
                }
            });

        } else {
            $('#global_loader').hide();
        }
    },

    LotModal : (value, callback) => {

        let visibleLength = $('.public-market-lot-container:visible').length;

        if (value < visibleLength) {

            $('#public_market_modal_lot_delete').show();

            $d.off('click', '#public_market_modal_lot_delete_cancel').on('click', '#public_market_modal_lot_delete_cancel', function(){
                $('#public_market_modal_lot_delete').hide();
                $('#public_market_lotNumber').val(visibleLength);
                return callback(false);
            });

            $d.off('click', '#public_market_modal_lot_delete_confirm').on('click', '#public_market_modal_lot_delete_confirm', function(){
                $('#public_market_modal_lot_delete').hide();
                return callback(true);
            });
        } else {
            return callback(true);
        }
    },

    countSubcontractorChecked : () => {
        $('#countSubcontractorsSelected').text($('input[name="subcontractors_selected[]"]').length);
    },
    
    SubcontractorListChoice : () => {

        PublicMarket.LoadEligibleSubcontractor();

        // >> TABLEAU DES SOUS-TRAITANS : Au changement de la checkbox de sélection.
        $d.off('change', 'input[name="subcontractor_list_choice[]"]').on('change', 'input[name="subcontractor_list_choice[]"]', function(){

            let isChecked = $(this).prop('checked'), id = $(this).val();

            if (isChecked && !$('#subcontractors_selected_'+id).length) {
                $('#subcontractors_selected').append(`
                    <input 
                        id="subcontractors_selected_${id}" 
                        type="hidden" name="subcontractors_selected[]" 
                        value="${id}" 
                    />
                `);

            } else {
                if ($('#subcontractors_selected_'+id).length) {
                    $('input#subcontractors_selected_'+id).remove();
                }
            }

            let isFull = true;

            $('input[name="subcontractor_list_choice[]"]:visible').each(function(index, item){
                let id = $(item).val();
                if ($('#subcontractors_selected_'+id).length) {
                    $(item).prop('checked', true).attr('checked', 'checked');
                } else {
                    $(item).prop('checked', false).removeAttr('checked');
                    isFull = false;
                }
            });

            if (isFull) {
                $('#subcontractor_list_select_visible_page').addClass('publicmarket-subcontractors-list-btn-active');
            } else {
                $('#subcontractor_list_select_visible_page').removeClass('publicmarket-subcontractors-list-btn-active');
            }
            PublicMarket.countSubcontractorChecked();
        });

        $d.off('click', '.publicmarket-subcontractors-list-check-all-reset').on('click', '.publicmarket-subcontractors-list-check-all-reset', function(){

            $('input.is-not-checked').each(function(index, item){
                let id = $(item).val()
                $(item).prop('checked', false).removeAttr('checked');
                if ($('#subcontractors_selected_'+id).length) {
                    $('#subcontractors_selected_'+id).remove();
                }
            });
            $('input.is-checked').each(function(index, item){
                let id = $(item).val()
                $(item).prop('checked', true).attr('checked', 'checked');
                if (!$('#subcontractors_selected_'+id).length) {
                    $('#subcontractors_selected').append(`
                        <input 
                            id="subcontractors_selected_${id}" 
                            type="hidden" name="subcontractors_selected[]" 
                            value="${id}" 
                        />
                    `);
                }
            });
            PublicMarket.countSubcontractorChecked();
        });

        $d.off('click', '#subcontractor_list_select_visible_page').on('click', '#subcontractor_list_select_visible_page', function(){

            if (!$(this).hasClass('publicmarket-subcontractors-list-btn-active')) {

                $(this).addClass('publicmarket-subcontractors-list-btn-active');

                if ($('#subcontractor_list_select_other_page').hasClass('publicmarket-subcontractors-list-btn-active')) {
                    $('#subcontractor_list_select_all_page').addClass('publicmarket-subcontractors-list-btn-active');
                }

                $('input[name="subcontractor_list_choice[]"]:visible').each(function(index, item){
                    let id = $(item).val();
                    $('input[name="subcontractor_list_choice[]"]').prop('checked', true).attr('checked', 'checked');
                    if (!$('#subcontractors_selected_'+id).length) {
                        $('#subcontractors_selected').append(`
                            <input 
                                id="subcontractors_selected_${id}" 
                                type="hidden" 
                                name="subcontractors_selected[]" 
                                value="${id}" 
                            />
                        `);
                    }
                });
            } else {
                $(this).removeClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_all_page').removeClass('publicmarket-subcontractors-list-btn-active');

                $('input[name="subcontractor_list_choice[]"]:visible').each(function(index, item){
                    let id = $(item).val();
                    $('input[name="subcontractor_list_choice[]"]').prop('checked', false).removeAttr('checked');
                    if ($('#subcontractors_selected_'+id).length) {
                        $('#subcontractors_selected_'+id).remove();
                    }
                });
            }
            PublicMarket.countSubcontractorChecked();
        });

        $d.off('click', '#subcontractor_list_select_other_page').on('click', '#subcontractor_list_select_other_page', function(){

            if (!$(this).hasClass('publicmarket-subcontractors-list-btn-active')) {

                $(this).addClass('publicmarket-subcontractors-list-btn-active');

                if ($('#subcontractor_list_select_visible_page').hasClass('publicmarket-subcontractors-list-btn-active')) {
                    $('#subcontractor_list_select_all_page').addClass('publicmarket-subcontractors-list-btn-active');
                }

                $('input[name="all_subcontractors[]"]').each(function(index, item){

                    let id = $(item).val();
                    id = id.replace([' ', "\n"], ['', '']);
                    id = id.trim();

                    if (!$('input[name="subcontractor_list_choice[]"][value="'+id+'"]').length) {

                        if (!$('#subcontractors_selected_'+id).length) {
                            $('#subcontractors_selected').append(`
                                <input
                                    id="subcontractors_selected_${id}"
                                    type="hidden"
                                    name="subcontractors_selected[]"
                                    value="${id}"
                                />
                            `);
                        }
                    }
                });
            } else {

                $(this).removeClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_all_page').removeClass('publicmarket-subcontractors-list-btn-active');

                $('input[name="all_subcontractors[]"]').each(function(index, item){
                    let id = $(item).val();
                    if (!$('input[name="subcontractor_list_choice[]"][value="'+id+'"]').length) {
                        if ($('#subcontractors_selected_'+id).length) {
                            $('#subcontractors_selected_'+id).remove();
                        }
                    }
                });
            }
            PublicMarket.countSubcontractorChecked();
        });

        $d.off('click', '#subcontractor_list_select_all_page').on('click', '#subcontractor_list_select_all_page', function(){

            if (!$(this).hasClass('publicmarket-subcontractors-list-btn-active')) {

                $(this).addClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_visible_page').addClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_other_page').addClass('publicmarket-subcontractors-list-btn-active');

                $('input[name="all_subcontractors[]"]').each(function(index, item){
                    let id = $(item).val();
                    $('input[name="subcontractor_list_choice[]"]').prop('checked', true).attr('checked', 'checked');
                    if (!$('#subcontractors_selected_'+id).length) {
                        $('#subcontractors_selected').append(`
                            <input 
                                id="subcontractors_selected_${id}" 
                                type="hidden" name="subcontractors_selected[]" 
                                value="${id}" 
                            />
                        `);
                    }
                });

            } else {
                $(this).removeClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_visible_page').removeClass('publicmarket-subcontractors-list-btn-active');
                $('#subcontractor_list_select_other_page').removeClass('publicmarket-subcontractors-list-btn-active');

                $('input[name="subcontractors_selected[]"]').each(function(index, item){
                    let id = $(item).val();
                    $('input[name="subcontractor_list_choice[]"]').prop('checked', false).removeAttr('checked');
                    $(item).remove();
                });
            }
            PublicMarket.countSubcontractorChecked();
        });

        // >> -- Au click sur la pagination du tableau Datatable, on met à jour les checkbox sélectionnées ou pas.. --
        $d.off('draw.dt', '#subcontractor_list_table').on('draw.dt', '#subcontractor_list_table', function (event, settings) {

            let isFull = true;

            $('input[name="subcontractor_list_choice[]"]:visible').each(function(index, item){
                let id = $(item).val();
                if ($('#subcontractors_selected_'+id).length) {
                    $(item).prop('checked', true).attr('checked', 'checked');
                } else {
                    $(item).prop('checked', false).removeAttr('checked');
                    isFull = false;
                }
            });

            if (isFull) {
                $('#subcontractor_list_select_visible_page').addClass('publicmarket-subcontractors-list-btn-active');
            } else {
                $('#subcontractor_list_select_visible_page').removeClass('publicmarket-subcontractors-list-btn-active');
            }
        });
        // <<


        //subcontractor_list_select_all_page

        //all_subcontractors_selected

        // >> -- Sélection uniquement de la page visible --
        /*$d.off('change', '#subcontractor_list_select_all').on('change', '#subcontractor_list_select_all', function(){
            
            if ($(this).prop('checked')) {

                $('input[name="subcontractor_list_choice[]"]').prop('checked', true).attr('checked', 'checked');

                if ($('#subcontractor_list_select_all_page').prop("checked") === true) {
                    $('#subcontractor_list_select_all_absolute').prop('checked', true);
                }
            } else {
                $('input[name="subcontractor_list_choice[]"]').prop('checked', false).removeAttr('checked');
                $('#subcontractor_list_select_all_absolute').prop('checked', false);
            }

            $('input[name="subcontractor_list_choice[]"]').each(function(index, item){

                let isChecked = $(item).prop('checked'), value = $(item).val();
                $('input[name="all_subcontractors_selected[]"][value="'+value+'"]').prop('checked', isChecked);
            });

            //PublicMarket.countSubcontractorChecked();
        });*/
        // <<

        // >> -- Sélection uniquement de toutes les autres pages --
        /*$d.off('change', '#subcontractor_list_select_all_page').on('change', '#subcontractor_list_select_all_page', function(){
            let valuesToEscape = [];
            let $that = $(this);

            if ($that.prop('checked')) {

                $('input[name="subcontractor_list_choice[]"]').each(function(index, item) {
                    valuesToEscape.push($(item).val());
                })

                $('input[name="all_subcontractors_selected[]"]').each(function(index, item) {

                    if ($.inArray($(item).val(), valuesToEscape) == -1 ) {
                        $(item).attr('checked', 'checked').prop('checked', true);
                    }
                })

                if ($('#subcontractor_list_select_all').prop("checked") === true) {
                    $('#subcontractor_list_select_all_absolute').prop('checked', true);
                }

            } else {

                $('input[name="subcontractor_list_choice[]"]').each(function(index, item) {
                    valuesToEscape.push($(item).val());
                })

                $('input[name="all_subcontractors_selected[]"]').each(function(index, item) {

                    if ($.inArray($(item).val(), valuesToEscape) == -1 ) {
                        $(item).removeAttr('checked').prop('checked', false);
                    }
                })
                $('#subcontractor_list_select_all_absolute').prop('checked', false);

            }
            PublicMarket.countSubcontractorChecked();
            return;
        });*/
        // <<

        // >> -- On clic for select all subcontractor or deselect all --
        /*$d.off('change', '#subcontractor_list_select_all_absolute').on('change', '#subcontractor_list_select_all_absolute', function(){

            if ($(this).prop('checked')) {
                $('#subcontractor_list_select_all_page').prop('checked', true);
                $('#subcontractor_list_select_all').prop('checked', true);

                $('input[name="subcontractor_list_choice[]"]').prop('checked', true).attr('checked', 'checked');
                
                $('input[name="all_subcontractors_selected[]"]').attr('checked', 'checked').prop('checked', true);
            } else {
                $('input[name="all_subcontractors_selected[]"]').removeAttr('checked').prop('checked', false);
                $('input[name="subcontractor_list_choice[]"]').prop('checked', false).removeAttr('checked');
                $('#subcontractor_list_select_all_page').prop('checked', false);
                $('#subcontractor_list_select_all').prop('checked', false);
            }
            PublicMarket.countSubcontractorChecked();
            return;
        });*/
        // <<

        // >> -- Ré-initialiser les valeurs des sous-traitans sélectionnés dans la liste --
        /*$d.off('click', '.publicmarket-subcontractors-list-check-all-reset').on('click', '.publicmarket-subcontractors-list-check-all-reset', function(){

            $('input[name="all_subcontractors_selected[]"]').removeAttr('checked').prop('checked', false);
            $('input.is-not-checked[name="subcontractor_list_choice[]"]').prop('checked', false).removeAttr('checked');
            $('input.is-checked[name="subcontractor_list_choice[]"]').prop('checked', true).attr('checked', 'checked');
            $('#subcontractor_list_select_all_absolute').prop('checked', false);
            $('#subcontractor_list_select_all_page').prop('checked', false);
            $('#subcontractor_list_select_all').prop('checked', false);
            PublicMarket.countSubcontractorChecked();
        });*/
        // <<

        // >> -- Au click sur la pagination du tableau Datatable, on met à jour les checkbox sélectionnées ou pas.. --
        /*$d.off('draw.dt', '#subcontractor_list_table').on('draw.dt', '#subcontractor_list_table', function (event, settings) {

            let arrayBoolean = [];

            $('input[name="all_subcontractors_selected[]"]').each(function(index, item){

                let isChecked = $(item).prop('checked'), value =$(item).val();

                $('input[name="subcontractor_list_choice[]"][value="'+value+'"]').prop('checked', isChecked).attr('checked', 'checked');
            });
            $('input[name="subcontractor_list_choice[]"]').each(function(index, item) {
                if ($(item).prop("checked") === true) {
                    arrayBoolean.push(true);
                }
            });
            if (arrayBoolean.length == $('input[name="subcontractor_list_choice[]"]').length) {
                $('#subcontractor_list_select_all').prop('checked', true);
            } else {
                $('#subcontractor_list_select_all').prop('checked', false);
            }

            let countAllSubcontractors = $('input[name="all_subcontractors_selected[]"]').length;
            let countSelectedSubcontractors = PublicMarket.countSubcontractorChecked();

            if ( countAllSubcontractors != countSelectedSubcontractors && $('#subcontractor_list_select_all').is(":checked") ) { 
                $('#subcontractor_list_select_all_page').prop('checked', false);
            } else {
                let listTableLength = $("select[name='subcontractor_list_table_length']").val();
                if ( (countAllSubcontractors - listTableLength) == countSelectedSubcontractors ) {
                    $('#subcontractor_list_select_all_page').prop('checked', true);
                }
            }
        });*/
        // <<

        /*$d.off('change', 'input[name="subcontractor_list_choice[]"]').on('change', 'input[name="subcontractor_list_choice[]"]', function(){

            let isChecked = $(this).prop('checked'), value = $(this).val();

            if (isChecked) {
                $('input[name="all_subcontractors_selected[]"][value="'+value+'"]').attr('checked', 'checked').prop('checked', true);
                $(this).prop('checked', true).attr('checked', 'checked');
            } else {
                $('input[name="all_subcontractors_selected[]"][value="'+value+'"]').removeAttr('checked').prop('checked', false);
                $('#subcontractor_list_select_all_page').prop('checked', false);
                $('#subcontractor_list_select_all').prop('checked', false);
                $(this).prop('checked', false).removeAttr('checked');
            }
            PublicMarket.countSubcontractorChecked();
        });*/

        // >> -- Au changement de département, on met à jour la liste des sous-traitans --
        $d.off('keyup keydown keypress', '#public_market_departments').on('keyup keydown keypress', '#public_market_departments', function(){

            let departments = $('#public_market_departments').val();
                departments = (departments.split(' ').join('')).toUpperCase();

                if (departments.indexOf('20') !== -1 && departments.indexOf('2A') !== -1) {
                    departments = departments.replace('20,', '');
                }
                departments = departments.replace('2A', '20');

                if (departments.indexOf('20') !== -1 && departments.indexOf('2B') !== -1) {
                    departments = departments.replace('20,', '');
                }
                departments = departments.replace('2B', '20');

            $('#public_market_departments').val(departments);
        });
        // <<

        // >> -- Au changement de département, on met à jour la liste des sous-traitans --
        $d.off('change', '#public_market_departments').on('change', '#public_market_departments', function(){
            PublicMarket.LoadEligibleSubcontractor();
        });
        // <<
    },

    SubcontractorsCheckbox : () => {

    },

    LoadEligibleSubcontractor : () => {

        let publicMarketId = $('input[name="publicmarket_id"]').val();

        if (publicMarketId && $('#publicmarket_subcontractors_container').length > 0) {

            $('#form_submit').prop('disabled', true);

            let $subcontractorContainer = $('#publicmarket_subcontractors_container');
            $subcontractorContainer.html('');

            let departments = $('#public_market_departments').val();

            $('#publicmarket_subcontractors_loader').show().find('span').text(departments);

            let vehicleTypeFields = $('select[name^="public_market[public_market_lot]"').filter(function () {
                let container = $(this.closest('.public-market-lot-container'));
                return this.name.match(/public_market\[public_market_lot\]\[[0-9]+\]\[vehicles_type\]/) && container.css('display') != 'none';
            });

            let vehiclesType = {
                vl: 0,
                pl: 0,
                mixte: 0,
            };

            vehicleTypeFields.each(function(index, item) {
                //let vehicleTypeFieldId = '#' + item.id;
                if (item.value == 'lti') { // VL
                    vehiclesType.vl = 1;
                } else if (item.value == 'lc') { // PL
                    vehiclesType.pl = 1;
                } else if (item.value == 'mixed') { // VL+PL
                    vehiclesType.mixte = 1;
                }
            });

            let vehiclesTypeKeyword;
            if (!vehiclesType.mixte && vehiclesType.vl && !vehiclesType.pl) {
                vehiclesTypeKeyword = "vl";
            } else if (!vehiclesType.mixte && !vehiclesType.vl && vehiclesType.pl) {
                vehiclesTypeKeyword = "pl";
            } else {
                vehiclesTypeKeyword = "vl_OR_pl";
            }

            let Ajax = $.ajax({
                url: '/publicmarket/edit/subcontractor/load',
                method: 'POST',
                dataType: 'HTML',
                data: {
                    publicmarket_id : publicMarketId,
                    departments: departments,
                    vehicles_type: vehiclesTypeKeyword,
                    view_only : $('#view_only').length > 0 ? $('#view_only').val() : '0'
                }
            });

            Ajax.done(function(result){
                if (result) {
                    $('#publicmarket_subcontractors_loader').hide();
                    $subcontractorContainer.html(result);

                    let table = require('./Table');
                    let lang = $('html:first').prop('lang');
                    if (!lang) {
                        lang = 'fr';
                    }
                    let tableParams = {
                        "language": {
                            "sUrl": '/publicmarket/datatable/lang/subcontractor',
                        },
                        "aLengthMenu": [ [10, 25, 50, 100, 1000, -1], [10, 25, 50, 100, 1000, 'Tous'] ],
                        fnDrawCallback: function(e) {
                            console.log(e);
                        }
                    }
                    table.DataTable('subcontractor_list_table', tableParams);
                }
                $('#form_submit').prop('disabled', false);
            });

            Ajax.fail(function(error){
                //console.log(error);
            });
        }

    },

    SelectAllSubcontractors : () => {
        setTimeout(function(){
            if ($('#subcontractor_list_table').length) {
                if ($('#subcontractor_list').attr('subcontractors-length') === '0') {
                    $('#subcontractor_list_select_all').prop('checked', true).trigger('change');
                    $('#subcontractor_list_select_all_page').prop('checked', true).trigger('change');
                }
            }
        },1000);
    },

    InputChangeSubcontractor : () => {

        $d.off('click', '#subcontractor_list_form_btn').on('click', '#subcontractor_list_form_btn', function(){
            
            let value           = ($('input#subcontractor_list_form').val()).toUpperCase(), count = 0;
            let $list           = $('#subcontractor_list');
            let $resultNotice   = $('#subcontractor_list_form_result_notice');

            if (value.length > 2) {

                $list.children('table').find('tbody > tr').hide();
                if ($('.jquery-pagination').length) $('.jquery-pagination').hide();

                $list.children('table').find('tbody > tr').each(function(index, item){

                    let text = ($(item).text()).toUpperCase();

                    if (text.indexOf(value) != -1) {

                        count = count + 1;
                        $(item).show();
                        $resultNotice.show().children('.count').text(count);
                    }

                });

            } else {
                $list.children('table').find('tbody > tr').show();
                $resultNotice.hide().children('.count').text('');
                if ($('.jquery-pagination').length) {
                    $('.jquery-pagination').show();
                    $('.jquery-pagination-page-current').trigger('click');
                }
            }
        });

        $d.off('click', '#subcontractor_list_form_clear').on('click', '#subcontractor_list_form_clear', function(){
            $('input#subcontractor_list_form').val('');
            $('#subcontractor_list').children('table').find('tbody > tr').show();
            $('#subcontractor_list_form_result_notice').hide().children('.count').text('');

            if ($('.jquery-pagination').length) {
                $('.jquery-pagination').show();
                $('.jquery-pagination-page-current').trigger('click');
            }
            
            return;
        });
    },

    InputChangeLot : () => {

        $('.public-market-lot-container').show();

        let field       = 'input#public_market_lotNumber'; // Nombre de lot element
        let fieldValue  = parseInt($(field).val()); // Nombre de lot valeur

        if (!isNaN(fieldValue)) PublicMarket.displayLots(fieldValue);

        $d.off('change', field).on('change', field, function(){

            let value = parseInt($(this).val());

            if (!isNaN(value)) {
                PublicMarket.CheckState();
                fieldValue = parseInt(value);
                
            } else {
                if (!isNaN(fieldValue)) {
                    $(this).val(fieldValue);
                    PublicMarket.displayLots(fieldValue);
                }
            }
        });

        $d.off('change.lot_vehicles_type', '.public_market_lot_vehicles_type').on('change.lot_vehicles_type', '.public_market_lot_vehicles_type', function(){
            PublicMarket.LoadEligibleSubcontractor();
        });
    },

    LotClone : ($clone, nbitem) => {

        $clone.find('#public_market_public_market_lot_0_title').attr({
            'id' : 'public_market_public_market_lot_'+nbitem+'_title',
            'name' : 'public_market[public_market_lot]['+nbitem+'][title]',
            'value' : '',
            'required' : 'required'
        });

        $clone.find('#public_market_public_market_lot_0_kilometers').attr({
            'id' : 'public_market_public_market_lot_'+nbitem+'_kilometers',
            'name' : 'public_market[public_market_lot]['+nbitem+'][kilometers]',
            'value' : '0',
            'required' : 'required'
        });

        let lastLotSelectedVehicleType = $('.public-market-lot-container').last().find('.public_market_lot_vehicles_type').val();
        $clone.find('#public_market_public_market_lot_0_vehicles_type').attr({
            'id' : 'public_market_public_market_lot_'+nbitem+'_vehicles_type',
            'name' : 'public_market[public_market_lot]['+nbitem+'][vehicles_type]',
            'required' : 'required'
        });

        // copy as last clone
        let lastLotSelectedVehicleTypeValue = $('.public-market-lot-container').last().find('.public_market_lot_vehicles_type').val();
        if (lastLotSelectedVehicleTypeValue) {
            $clone.find('.public_market_lot_vehicles_type').val(lastLotSelectedVehicleTypeValue);
        }

        if ($clone.find('.ajax-upload-label').length) {

            let objectId = (($clone.find('.ajax-upload-label').children('input.ajax-upload').attr('object-id')).split('-'))[0]+'-'+nbitem;

            $clone.find('.ajax-upload-list-file').remove();
            $clone.find('.ajax-upload-label').attr('for', 'public_market_public_market_lot_'+nbitem+'_specifications');
            $clone.find('.ajax-upload-label').children('input.ajax-upload').attr({
                'id' : 'public_market_public_market_lot_'+nbitem+'_specifications',
                'name' : 'public_market[public_market_lot]['+nbitem+'][specifications]',
                'object-name' : 'public_market_lot_specification_'+nbitem,
                'object-id' : objectId,
                'value' : ''
            });
            $clone.find('.ajax-upload-label').children('input.ajax-upload-required').attr({
                'id' : 'ajax_upload_is_required_public_market_lot_specification_'+nbitem+'_'+objectId,
                'name' : 'ajax_upload_is_required_public_market_lot_specification_'+nbitem+'_'+objectId, 
                'value' : ''
            });
            if ($clone.find('.ajax-current-file')) {
                $clone.find('.ajax-current-file').remove();
            }
            $clone
                .find('#ajax_upload_list_files_public_market_public_market_lot_0_specifications')
                .attr('id', 'ajax_upload_list_files_public_market_public_market_lot_'+nbitem+'_specifications');
        }

        $clone.attr('data-number', nbitem);
        $clone.find('.lot-number').children('span').text(nbitem+1);

        $($clone).insertAfter(($('.public-market-lot-container').last()));

        $('#public_market_public_market_lot_'+nbitem+'_title').val('').attr('required', 'required');
        $('#public_market_public_market_lot_'+nbitem+'_kilometers').val('0').attr('required', 'required');
        $('#public_market_public_market_lot_'+nbitem+'_specifications').val('');

        return $clone;

    },

    displayLots: (fieldValue) => {
        
        let nbitem = $('.public-market-lot-container').length;
        let count = 0;

        if (fieldValue > 0 && fieldValue > nbitem) {

            $('.public-market-lot-nav-item').hide();
            $('.public-market-lot-container').hide();

            for (var i=0; i < fieldValue; i++) {

                $('.public-market-lot-nav-item[data-number="'+i+'"]').show();
                $('.public-market-lot-container[data-number="'+i+'"]').show();

                if (!$('.public-market-lot-container[data-number="'+i+'"]').length) {
                    let $clone = $('.public-market-lot-container[data-number="0"]').first().clone();
                    PublicMarket.LotClone($clone, i);
                }
            }
        }

        if (fieldValue > 0 && fieldValue <= nbitem) {

            $('.public-market-lot-nav-item, .public-market-lot-container').hide();

            for (var i=0; i < fieldValue; i++) {

                $('.public-market-lot-nav-item[data-number="'+i+'"]').show();
                $('.public-market-lot-container[data-number="'+i+'"]').show();

                count ++;
                if (count === fieldValue) {
                    $('.public-market-lot-container:hidden').remove();
                }
            }
        }
    },
    
    addAllDepartments: () => {

        let inputDepartments = document.getElementById('public_market_departments');

        if ('' == inputDepartments.value) {

            let aDepartments = [];

            for (let i = 1; i <= 95; ++i) {
                if (2 == i) {
                    //aDepartments.push('2A');
                    //aDepartments.push('2B');
                    aDepartments.push('20');
                    continue;
                }

                let department = i.toString().padStart(2, '0');
                aDepartments.push(department);
            }

            inputDepartments.value = aDepartments.join(',');
        } else {

            inputDepartments.value = '';
        }
        PublicMarket.LoadEligibleSubcontractor();
    },

    ChangeKilometersOnUpload: () => {

        const statusValue = $('#public_market_status').val();

        $d.off('change', '.public_market_lot-input-file').on('change', '.public_market_lot-input-file', function() {

            const fileInput = $(this);
            const lotFields = fileInput.parents('.item-lot-fields');
            const kilometerInput = lotFields.find(".public-market-lot-kilometers");
    
            if (fileInput.val()) {
                kilometerInput.prop('disabled', false);
            }

            if (statusValue !== "draft" || parseInt($('#public_market_current_turn').val()) > 1) {
                setTimeout(function() {
                    const buttonDelete = lotFields.find(".ajax-upload-file-delete");
                    buttonDelete.on('click', function() {
                        kilometerInput.prop('disabled', true);
                    });
                }, 3000);
            }

        });

    }

}
module.exports = PublicMarket;
