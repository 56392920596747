let AltaresSubscription = {

    init : () => {

        if ($('#altares_subscription_table_list_select_all').length) {

            $d.off('change', '#altares_subscription_table_list_select_all').on('change', '#altares_subscription_table_list_select_all', function(){

                let is_checked = $(this).prop('checked');

                $('input[name="altares_subscription[]"]').each(function(index, item){

                    let society_id = $(item).val();
                    console.log(society_id)

                    $(item).prop('checked', is_checked);
                    $(item)
                        .parent().parent()
                        .find('input[name="altares_subscription_list['+society_id+'][]"]')
                        .prop('checked', is_checked)
                    ;

                    if (is_checked) {

                        $(item).attr('checked', 'checked');
                        $(item)
                            .parent()
                            .parent()
                            .find('input[name="altares_subscription_list['+society_id+'][]"]')
                            .attr('checked', 'checked')
                        ;
                    } else {
                        $(item).removeAttr('checked');
                        $(item)
                            .parent()
                            .parent()
                            .find('input[name="altares_subscription_list['+society_id+'][]"]')
                            .removeAttr('checked')
                        ;
                    }

                });
                

            });

            $d.off('change', 'input[name="altares_subscription[]"]').on('change', 'input[name="altares_subscription[]"]', function(){

                let $that = $(this);
                let is_checked = $(this).prop('checked');
                let society_id =  $that.val();
                console.log(society_id)

                $that.prop('checked', is_checked);
                $that
                    .parent().parent()
                    .find('input[name="altares_subscription_list['+society_id+'][]"]')
                    .prop('checked', is_checked)
                ;

                if (is_checked) {

                    $that.attr('checked', 'checked');
                    $that
                        .parent()
                        .parent()
                        .find('input[name="altares_subscription_list['+society_id+'][]"]')
                        .attr('checked', 'checked')
                    ;
                } else {
                    $that.removeAttr('checked');
                    $that
                        .parent()
                        .parent()
                        .find('input[name="altares_subscription_list['+society_id+'][]"]')
                        .removeAttr('checked')
                    ;
                }

            })
        }

    }
}
module.exports = AltaresSubscription;